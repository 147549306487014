@import url("https://fonts.googleapis.com/css?family=Rubik+Glitch");
@import url("https://fonts.googleapis.com/css?family=Poppins:wght@700");
@import url("https://fonts.googleapis.com/css?family=Chakra+Petch");
@import url("https://fonts.googleapis.com/css?family=Oswald");
@import url("https://fonts.googleapis.com/css?family=Inconsolata");
@import url("https://fonts.googleapis.com/css?family=Lilita+One");
@import url('https://fonts.googleapis.com/css2?family=Inter');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono');
@import url('https://fonts.googleapis.com/css2?family=Kanit');

@font-face {
  font-family: 'DS-DIGIB';
  src: url('./assets/fonts/DS-DIGIB.TTF') format('truetype');
  /* You can add more font properties if needed */
}

@font-face {
  font-family: 'COMPACTA';
  src: url('./assets/fonts/Compacta-Reg.ttf') format('truetype');
  /* You can add more font properties if needed */
}

.time-remaining {
  font-size: 40px; /* Default font size for larger screens */
}
.countdown {
  font-size: 110px; /* Default font size for larger screens */
  margin-top: -50px;
}

@media (max-width: 768px) {
  .kyberswap-widget {
      display: none;
  }
  .time-remaining {
      font-size: 25px; /* Font size for mobile devices */
    }

  .countdown {
    margin-top: -25px;
    font-size: 70px; /* Font size for mobile devices */
  }

  .logo {
    display: none;
  }

  .chart {
    margin-top: 30px;
    height: 100vh;
  }
}

.App {
  text-align: center;
  font-family: "Chakra Petch","Oswald","Lilita One","DS-DIGIB","COMPACTA";
  color: white;
/*  background-color: #212429; */
}


* {
box-sizing: border-box;
}

body {

  overflow: hidden;
}

.overlay {
  opacity: 1; 
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.static-background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./assets/background/vaultbg2.png'); 
  color: rgba(10, 0, 86, 0.5); /* Black background with 50% opacity */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 40% 40%;

  &:before {
    content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom right,#051d4c,#000000);
        opacity: 0.3; 
  }
}
